const dataHome1 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/las-verde-web.appspot.com/o/Im%C3%A1genes%20home%2FsinAgua.png?alt=media&token=b3101417-5508-4af5-ac2a-5903e37f143a",
    title: "SIN AGUA",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/las-verde-web.appspot.com/o/Im%C3%A1genes%20home%2FsinColorantes.png?alt=media&token=7dc34cb0-e00a-49fe-bd95-ecdfe917806b",
    title: "SIN COLORANTES",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/las-verde-web.appspot.com/o/Im%C3%A1genes%20home%2FsinCoservantes.png?alt=media&token=1ea6b3ab-e878-4f2e-9fa5-688e00204127",
    title: "SIN CONSERVANTES",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/las-verde-web.appspot.com/o/Im%C3%A1genes%20home%2FsinEndulzantes.png?alt=media&token=14565358-d8fe-4806-a5c1-99bf58874c07",
    title: "SIN ENDULZANTES",
  },
];

export default dataHome1;
